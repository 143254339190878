import React, { useCallback, useEffect, useState, lazy } from 'react';
import { Switch, useHistory, useLocation } from 'react-router-dom';
import {
  faExclamationCircle,
  faUnlink,
} from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';
import SideMenu from '../views/sidemenu/side-menu';
import { Message } from '@naadi/framework';
import { useDispatch, useSelector } from 'react-redux';
import { toggleSideMenu } from '../store/app/nav/actions';
import { CompatRoute, CompatRouter } from 'react-router-dom-v5-compat';
import PrivateRoute from './private-route';
import OrderProgress from '../views/order/order-progress';
import ProductionSummary from '../views/order/production-summary';
import UploadFormat from '../views/uploadformat/uploadformat';
import AddItemFeatures from '../views/uploadformat/additemfeatures';
import DownloadFormat from '../views/downloadformat/downloadformat';
import DownloadAdd from '../views/downloadformat/downloadadd';
import Bots from '../views/bot/bots';
import BotBox from '../views/bot/box';
import NewBot from '../views/bot/newbot';
import Opertions from '../views/operations/operation';
import NewOperation from '../views/operations/newoperation';
import WorkFlow from '../views/workflowrules/workflow';
import NewWorkFlow from '../views/workflowrules/newworkflow';
import Stations from '../views/stations/station';
import NewStation from '../views/stations/newstation';
import WorkStation from '../views/workstations/workstation';
import NewWorkStation from '../views/workstations/newworkstation';
import CutGuideWo from '../views/cutguide/cutguide-wo';
import ChildOrderManagement from '../views/order/order-improvement/child-order-management';
import CutSmartSetting from '../views/cutsmart/cutsmart-setting';
import CustomizeOperationSequence from '../components/order/progress/customize-operation-sequence';
import DigitalPodExternalHome from '../views/digitalpod/digitalpod-external';
import ProductionSummaryRFT from '../views/order/production-summary-RFT';
import useDomain from '../hooks/useDomain';

const OrgList = lazy(() => import('../views/org/orgList'));
const OrgFormPage = lazy(() => import('../views/org/orgCreate'));
const ProfileList = lazy(() => import('../views/profiles/profileList'));
const ProfileCreate = lazy(() => import('../views/profiles/profileCreate'));
const GroupList = lazy(() => import('../views/groups/group-list'));
const RejectionReason = lazy(() =>
  import('../views/rejection/rejection-reason'),
);
const NewRejectionReason = lazy(() =>
  import('../views/rejection/new-rejection-reason'),
);
const GroupForm = lazy(() => import('../views/groups/group-form'));
const UserList = lazy(() => import('../views/users/user-list'));
const UserForm = lazy(() => import('../views/users/user-form'));
const TemplateCategory = lazy(() =>
  import('../views/labeltemplates/templatecategory'),
);
const Box = lazy(() => import('../views/labeltemplates/box'));
const NewBox = lazy(() => import('../views/labeltemplates/newbox'));
const Dashboard = lazy(() => import('./dashboard'));
const ReportHome = lazy(() => import('./reportHome'));
const CreateShipment = lazy(() => import('./create-shipment'));
const ShipmentHome = lazy(() => import('./shipment'));
const PackingHome = lazy(() => import('./packingHome'));
const OrderListPage = lazy(() => import('./ordersHome'));
const OrderCreatePage = lazy(() => import('../views/order/createOrder'));
const OrderDetailsPage = lazy(() => import('../views/order/orderDetail'));
const OrderBulkUploadMain = lazy(() => import('../views/order/bulk-upload'));
const ClipCanvas = lazy(() => import('./clipcanvas'));
const TrackTraceHome = lazy(() => import('../views/tracktrace/tracktrace'));
const WorkOrderList = lazy(() => import('../views/cutsmart/workorder-list'));
const WorkOrderDetail = lazy(() =>
  import('../views/cutsmart/workorder-detail'),
);
const ItemListPage = lazy(() => import('./itemsHome'));
const CreateItem = lazy(() => import('../views/item/create-item'));
const CutGuideHome = lazy(() => import('../views/cutguide/cutguide'));
const DigitalPodHome = lazy(() => import('../views/digitalpod/digitalpod'));

const renderPrivateRoute = element => {
  return <PrivateRoute>{element}</PrivateRoute>;
};
const NaadiRoutes = () => {
  const { sideMenuToggled } = useSelector(state => state.appNav);
  const dispatch = useDispatch();
  const [domain] = useDomain();
  const [wrapperClass, setWrapperClass] = useState('');
  useEffect(() => {
    setWrapperClass(
      'wrapper wrapper-full-page' +
        (sideMenuToggled ? ' is-side-bar-toggled' : ''),
    );
  }, [sideMenuToggled, setWrapperClass]);
  const handleSideMenuToggle = useCallback(() => {
    dispatch(toggleSideMenu());
  }, [dispatch]);
  return (
    <div className={wrapperClass}>
      <CompatRouter>
        <SideMenu selected='PACKING' />
        <div className='main-panel d-block'>
          <div
            className='main-panel-toggle-overlay'
            onClick={handleSideMenuToggle}
          ></div>
          <Switch>
            <CompatRoute
              path='/app/orders/progress/customize-stations'
              render={() => {
                return renderPrivateRoute(<CustomizeOperationSequence />);
              }}
            />
            <CompatRoute
              path='/app/dashboard'
              render={() => {
                if (domain === 'dpod') {
                  return renderPrivateRoute(<DigitalPodExternalHome />);
                }
                return renderPrivateRoute(<Dashboard />);
              }}
            />
            <CompatRoute
              path={`/app/org/list`}
              render={() => {
                return renderPrivateRoute(<OrgList />);
              }}
            />
            <CompatRoute
              path={`/app/org/form`}
              render={() => {
                return renderPrivateRoute(<OrgFormPage />);
              }}
            />

            <CompatRoute
              path={`/app/profiles/list`}
              render={() => {
                return renderPrivateRoute(<ProfileList />);
              }}
            />
            <CompatRoute
              path={`/app/profiles/form`}
              render={() => {
                return renderPrivateRoute(<ProfileCreate />);
              }}
            />
            <CompatRoute
              path={`/app/users/list`}
              render={() => {
                return renderPrivateRoute(<UserList />);
              }}
            />
            <CompatRoute
              path={`/app/users/form`}
              render={() => {
                return renderPrivateRoute(<UserForm />);
              }}
            />

            <CompatRoute
              path={`/app/groups/list`}
              render={() => {
                return renderPrivateRoute(<GroupList />);
              }}
            />
            <CompatRoute
              path={`/app/rejection/list`}
              render={() => {
                return renderPrivateRoute(<RejectionReason />);
              }}
            />
            <CompatRoute
              path={`/app/rejection/new-rejection-reason`}
              render={() => {
                return renderPrivateRoute(<NewRejectionReason />);
              }}
            />
            <CompatRoute
              path={`/app/groups/form`}
              render={() => {
                return renderPrivateRoute(<GroupForm />);
              }}
            />
            <CompatRoute
              path={`/app/labeltemplates/templatecategory`}
              render={() => {
                return renderPrivateRoute(<TemplateCategory />);
              }}
            />
            <CompatRoute
              path={`/app/labeltemplates/box`}
              render={() => {
                return renderPrivateRoute(<Box />);
              }}
            />
            <CompatRoute
              path={`/app/labeltemplates/newbox`}
              render={() => {
                return renderPrivateRoute(<NewBox />);
              }}
            />
            <CompatRoute
              path={`/app/uploadformat/uploadformat`}
              render={() => {
                return renderPrivateRoute(<UploadFormat />);
              }}
            />
            <CompatRoute
              path={`/app/uploadformat/additemfeatures`}
              render={() => {
                return renderPrivateRoute(<AddItemFeatures />);
              }}
            />
            <CompatRoute
              path={`/app/downloadformat/downloadformat`}
              render={() => {
                return renderPrivateRoute(<DownloadFormat />);
              }}
            />
            <CompatRoute
              path={`/app/downloadformat/downloadadd`}
              render={() => {
                return renderPrivateRoute(<DownloadAdd />);
              }}
            />
            <CompatRoute
              path={`/app/bot/bots`}
              render={() => {
                return renderPrivateRoute(<Bots />);
              }}
            />
            <CompatRoute
              path={`/app/bot/box`}
              render={() => {
                return renderPrivateRoute(<BotBox />);
              }}
            />
            <CompatRoute
              path={`/app/bot/newbot`}
              render={() => {
                return renderPrivateRoute(<NewBot />);
              }}
            />
            <CompatRoute
              path={`/app/operations/operation`}
              render={() => {
                return renderPrivateRoute(<Opertions />);
              }}
            />
            <CompatRoute
              path={`/app/operations/newoperation`}
              render={() => {
                return renderPrivateRoute(<NewOperation />);
              }}
            />
            <CompatRoute
              path={`/app/stations/station`}
              render={() => {
                return renderPrivateRoute(<Stations />);
              }}
            />
            <CompatRoute
              path={`/app/stations/newstation`}
              render={() => {
                return renderPrivateRoute(<NewStation />);
              }}
            />
            <CompatRoute
              path={`/app/workstations/workstation`}
              render={() => {
                return renderPrivateRoute(<WorkStation />);
              }}
            />
            <CompatRoute
              path={`/app/workstations/newworkstation`}
              render={() => {
                return renderPrivateRoute(<NewWorkStation />);
              }}
            />
            <CompatRoute
              path={`/app/workflowrules/workflow`}
              render={() => {
                return renderPrivateRoute(<WorkFlow />);
              }}
            />
            <CompatRoute
              path={`/app/workflowrules/newworkflow`}
              render={() => {
                return renderPrivateRoute(<NewWorkFlow />);
              }}
            />
            <CompatRoute
              path='/app/packing/:OrgId/:StationId/:id'
              render={() => {
                return renderPrivateRoute(<PackingHome />);
              }}
            />
            <CompatRoute
              path='/app/packing/:OrgId/:StationId'
              render={() => {
                return renderPrivateRoute(<PackingHome />);
              }}
            />
            <CompatRoute
              path='/app/packing/:OrgId'
              render={() => {
                return renderPrivateRoute(<PackingHome />);
              }}
            />
            <CompatRoute
              path='/app/packing'
              render={() => {
                return renderPrivateRoute(<PackingHome />);
              }}
            />
            <CompatRoute
              path='/app/shipment/create'
              render={() => {
                return renderPrivateRoute(<CreateShipment />);
              }}
            />
            <CompatRoute
              path='/app/shipment/edit/:id'
              render={() => {
                return renderPrivateRoute(<CreateShipment />);
              }}
            />

            <CompatRoute path='/app/clipcanvas' component={ClipCanvas} />
            <CompatRoute
              path='/app/shipment/:OrgId/:StationId/:ShipmentUuid/:OrderUuid'
              render={() => {
                return renderPrivateRoute(<ShipmentHome />);
              }}
            />
            <CompatRoute
              path='/app/shipment/:OrgId/:StationId/:ShipmentUuid'
              render={() => {
                return renderPrivateRoute(<ShipmentHome />);
              }}
            />
            <CompatRoute
              path='/app/shipment/:OrgId/:StationId'
              render={() => {
                return renderPrivateRoute(<ShipmentHome />);
              }}
            />
            <CompatRoute
              path='/app/shipment/:OrgId'
              render={() => {
                return renderPrivateRoute(<ShipmentHome />);
              }}
            />

            <CompatRoute
              path='/app/shipment'
              render={() => {
                return renderPrivateRoute(<ShipmentHome />);
              }}
            />
            <CompatRoute
              path='/app/tracktrace/home/:OrgId/:StationId'
              render={() => {
                return renderPrivateRoute(<TrackTraceHome />);
              }}
            />
            <CompatRoute
              path='/app/tracktrace/home/:OrgId'
              render={() => {
                return renderPrivateRoute(<TrackTraceHome />);
              }}
            />
            <CompatRoute
              path='/app/tracktrace/home'
              render={() => {
                return renderPrivateRoute(<TrackTraceHome />);
              }}
            />
            <CompatRoute
              path='/app/tracktrace'
              render={() => {
                return renderPrivateRoute(<TrackTraceHome />);
              }}
            />

            <CompatRoute
              path='/app/cutguide/home/:OrgId/:StationId'
              render={() => {
                return renderPrivateRoute(<CutGuideHome />);
              }}
            />

            <CompatRoute
              path='/app/cutguide/home/:OrgId'
              render={() => {
                return renderPrivateRoute(<CutGuideHome />);
              }}
            />
            <CompatRoute
              path='/app/cutguide/materials/:OrgId/:WoId/pattern/:MatId/:PatternId'
              render={() => {
                return renderPrivateRoute(<CutGuideWo mode={'cutguide'} />);
              }}
            />
            <CompatRoute
              path='/app/cutguide/materials/:OrgId/:WoId/pattern/:MatId'
              render={() => {
                return renderPrivateRoute(<CutGuideWo mode={'patterns'} />);
              }}
            />
            <CompatRoute
              path='/app/cutguide/materials/:OrgId/:WoId'
              render={() => {
                return renderPrivateRoute(<CutGuideWo mode={'material'} />);
              }}
            />

            <CompatRoute
              path='/app/cutguide/home'
              render={() => {
                return renderPrivateRoute(<CutGuideHome />);
              }}
            />

            <CompatRoute
              path='/app/cutguide'
              render={() => {
                return renderPrivateRoute(<CutGuideHome />);
              }}
            />

            <CompatRoute
              path='/app/cutsmart/home'
              render={() => {
                return renderPrivateRoute(<WorkOrderList />);
              }}
            />
            <CompatRoute
              path='/app/cutsmart/detail/:org_id/:wo_id'
              render={() => {
                return renderPrivateRoute(<WorkOrderDetail />);
              }}
            />
            <CompatRoute
              path='/app/cutsmart/cutsmartsetting'
              render={() => {
                return renderPrivateRoute(<CutSmartSetting />);
              }}
            />
            <CompatRoute
              path='/app/cutsmart'
              render={() => {
                return renderPrivateRoute(<WorkOrderList />);
              }}
            />
            <CompatRoute
              path='/app/digitalpod/home/:OrgId/:StationId'
              render={() => {
                return renderPrivateRoute(<DigitalPodHome />);
              }}
            />
            <CompatRoute
              path='/app/digitalpod/home/:OrgId'
              render={() => {
                return renderPrivateRoute(<DigitalPodHome />);
              }}
            />
            <CompatRoute
              path='/app/digitalpod/home'
              render={() => {
                return renderPrivateRoute(<DigitalPodHome />);
              }}
            />

            <CompatRoute
              path='/app/digitalpod'
              render={() => {
                return renderPrivateRoute(<DigitalPodHome />);
              }}
            />
            <CompatRoute
              path='/app/dpodexternal/home'
              render={() => {
                return renderPrivateRoute(<DigitalPodExternalHome />);
              }}
            />

            <CompatRoute
              path='/app/dpodexternal'
              render={() => {
                return renderPrivateRoute(<DigitalPodExternalHome />);
              }}
            />
            <CompatRoute
              path='/app/items/list/createItem'
              render={() => {
                return renderPrivateRoute(<CreateItem />);
              }}
            />
            <CompatRoute
              path='/app/items/details/:id'
              render={() => {
                return renderPrivateRoute(<CreateItem />);
              }}
            />
            <CompatRoute
              path='/app/items/list'
              render={() => {
                return renderPrivateRoute(<ItemListPage />);
              }}
            />

            <CompatRoute
              path='/app/orders/bulkupload'
              render={() => {
                return renderPrivateRoute(<OrderBulkUploadMain />);
              }}
            />
            <CompatRoute
              path='/app/orders/progress'
              render={() => {
                return renderPrivateRoute(<OrderProgress />);
              }}
            />
            <CompatRoute
              path='/app/orders/pssummary'
              render={() => {
                return renderPrivateRoute(<ProductionSummary />);
              }}
            />
            <CompatRoute
              path='/app/orders/prodsummary-rft'
              render={() => {
                return renderPrivateRoute(<ProductionSummaryRFT />);
              }}
            />

            <CompatRoute
              path='/app/orders/create'
              render={() => {
                return renderPrivateRoute(<OrderCreatePage />);
              }}
            />
            <CompatRoute
              path='/app/orders/create(:opening_id)'
              render={() => {
                return renderPrivateRoute(<OrderCreatePage />);
              }}
            />
            <CompatRoute
              path='/app/orders/details/:id'
              render={() => {
                return renderPrivateRoute(<OrderDetailsPage />);
              }}
            />
            <CompatRoute
              path='/app/orders/list'
              render={() => {
                return renderPrivateRoute(<OrderListPage />);
              }}
            />

            <CompatRoute
              path='/app/order/order-improvement/child-order-management'
              render={() => {
                return renderPrivateRoute(<ChildOrderManagement />);
              }}
            />
            <CompatRoute
              path='/app/report'
              render={() => {
                return renderPrivateRoute(<ReportHome />);
              }}
            />
            <CompatRoute
              path='/private'
              render={() => {
                if (domain === 'dpod') {
                  return renderPrivateRoute(<DigitalPodExternalHome />);
                }
                return <Dashboard />;
              }}
            />
            <CompatRoute
              path='/&error=login_required'
              render={() => {
                return <Redirect />;
              }}
            />
            <CompatRoute
              path='/401'
              exact
              render={() => {
                return <Unauthorized />;
              }}
            />
            <CompatRoute
              path='/redirect'
              render={() => {
                return <Redirect />;
              }}
            />
            <CompatRoute
              path='/'
              render={() => {
                if (domain === 'dpod') {
                  return renderPrivateRoute(<DigitalPodExternalHome />);
                }
                return renderPrivateRoute(<Dashboard />);
              }}
            />

            <CompatRoute path='*' component={NotFound} />
            <CompatRoute
              path='/app'
              render={() => {
                if (domain === 'dpod') {
                  return renderPrivateRoute(<DigitalPodExternalHome />);
                }
                return renderPrivateRoute(<Dashboard />);
              }}
            />
          </Switch>
        </div>
      </CompatRouter>
    </div>
  );
};

const Unauthorized = () => (
  <Message
    heading={<FormattedMessage id='UNAUTHORIZED' />}
    icon={faExclamationCircle}
  >
    <FormattedMessage id='YOU-ARE-NOT-AUTHORIZED' />
  </Message>
);

const Redirect = () => {
  const location = useLocation();

  const history = useHistory();

  useEffect(() => {
    if (location?.state?.pathname) {
      history.push(location.state.pathname);
    }
  }, [location, history]);

  return (
    <Message heading={<FormattedMessage id='404' />} icon={faUnlink}>
      <FormattedMessage id='PAGE-NOT-FOUND' />
    </Message>
  );
};

const NotFound = () => {
  const location = useLocation();

  const history = useHistory();

  useEffect(() => {
    if (location.pathname.includes('&state')) {
      history.push({
        pathname: '/redirect',
        state: { pathname: location.pathname.split('&state')[0] },
      });
    }
  }, [location, history]);

  return (
    <Message heading={<FormattedMessage id='404' />} icon={faUnlink}>
      <FormattedMessage id='PAGE-NOT-FOUND' />
    </Message>
  );
};

export default NaadiRoutes;
