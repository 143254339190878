import React, { Fragment, memo, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setProductionSummaryGroup,
  setProductionSummaryGroup2,
  setPsCustomDateRange,
  setPsItemType,
  setPsOperationCode,
  setPsUnitType,
} from '../../../store/order/orderstate/orderstate';
import Dropdown from 'react-bootstrap/Dropdown';
import { DateRangePickers } from '../../common/date-range-picker';
import { GROUPS } from '../../../helpers/order/progressHelper';
const ChevronDownIcon = memo(() => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='8'
      fill='none'
      viewBox='0 0 12 8'
    >
      <path fill='#007BFF' d='M1.41.59L6 5.17 10.59.59 12 2 6 8 0 2 1.41.59z' />
    </svg>
  );
});
const PsOperationDropdown = memo(({ operations }) => {
  const dispatch = useDispatch();
  const [is_dropdown_open, setDropdownState] = useState(false);
  const { ps_operation_code } = useSelector(state => state.orderstate);

  const [selectedOperation, setSelectedOperation] = useState({});
  useEffect(() => {
    const filterVal =
      operations.filter(val => val.operation_code === ps_operation_code)[0] ||
      {};
    setSelectedOperation({
      operation_code: filterVal.operation_code,
      name: filterVal.name,
    });
  }, [dispatch, operations, ps_operation_code, setSelectedOperation]);
  /* eslint-disable sonarjs/no-identical-functions */
  const CustomToggle = React.forwardRef(
    (
      { children, onClick },
      ref, //eslint-disable-line
    ) => (
      <div //eslint-disable-line
        className={`title-sm cursor-pointer page-size-display ${
          is_dropdown_open ? 'active' : ''
        }`}
        ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </div>
    ),
  );
  /* eslint-enable sonarjs/no-identical-functions */

  const onDropdownSelect = data => {
    dispatch(setPsOperationCode(data.operation_code));
  };

  const onDropdownToggle = status => {
    setDropdownState(status);
  };

  return (
    <div className='d-flex'>
      <div id='track-trace-group-filter-wrp' style={{ maxWidth: '300px' }}>
        <Dropdown
          id='track-trace-group-filter'
          className='page-size-selector'
          onToggle={onDropdownToggle}
          drop={'right'}
          flip={'false'}
        >
          <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
            <span className={`btn-label text-primary pe-2 `}>
              {selectedOperation.operation_code
                ? selectedOperation.name
                : 'Select'}
            </span>
            <ChevronDownIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu align={{ sm: 'right' }}>
            {operations.map(filter => (
              <Dropdown.Item
                onClick={e => onDropdownSelect(filter)}
                key={filter.operation_code}
                eventKey={filter.operation_code}
                active={filter.operation_code === ps_operation_code}
                className='track-trace-grp-dropdown-item'
              >
                {filter.name}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
});
const PsRFTOperationDropdown = memo(({ operations }) => {
  const dispatch = useDispatch();
  const CustomToggle = React.forwardRef(
    (
      { children, onClick },
      ref, //eslint-disable-line
    ) => (
      <div //eslint-disable-line
        className={`title-sm cursor-pointer page-size-display`}
        ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </div>
    ),
  );
  /* eslint-enable sonarjs/no-identical-functions */
  useEffect(() => {
    dispatch(setPsOperationCode(operations[0]?.operation_code));
  }, [dispatch, operations]);

  return (
    <div className='d-flex'>
      <div id='track-trace-group-filter-wrp' style={{ maxWidth: '300px' }}>
        <Dropdown
          id='track-trace-group-filter'
          className='page-size-selector'
          drop={'right'}
          flip={'false'}
        >
          <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
            <span className={`btn-label text-primary pe-2 `}>{'Edging'}</span>
          </Dropdown.Toggle>
        </Dropdown>
      </div>
    </div>
  );
});

PsOperationDropdown.propTypes = {};

const PsItemTypeFilterDropdown = memo(() => {
  const dispatch = useDispatch();
  const { ps_item_type } = useSelector(state => state.orderstate);
  const [filters] = useState([
    {
      key: 'ALL',
      label: 'All',
    },
    {
      key: 'PART',
      label: 'Part',
    },
    {
      key: 'HARDWARE',
      label: 'Hardware',
    },
    {
      key: 'BOARD',
      label: 'Board',
    },
    {
      key: 'PART_BOARD',
      label: 'Parts & Boards',
    },
  ]);
  const [selectedItemType, setSelectedItemType] = useState({
    key: 'ALL',
    label: 'All',
  });
  useEffect(() => {
    const filterVal = filters.filter(val => val.key === ps_item_type)[0] || {};
    setSelectedItemType(filterVal);
  }, [filters, ps_item_type, setSelectedItemType]);

  const onDropdownSelect = data => {
    dispatch(setPsItemType(data.key));
  };

  return (
    <PsFilterDropDown
      filters={filters}
      selectedVal={selectedItemType}
      onDropdownSelect={onDropdownSelect}
    />
  );
});

const PsFilterDropDown = memo(({ filters, selectedVal, onDropdownSelect }) => {
  const [is_dropdown_open, setDropdownState] = useState(false);
  /* eslint-disable sonarjs/no-identical-functions */
  const CustomToggle = React.forwardRef(
    (
      { children, onClick },
      ref, //eslint-disable-line
    ) => (
      <div //eslint-disable-line
        className={`title-sm cursor-pointer page-size-display ${
          is_dropdown_open ? 'active' : ''
        }`}
        ref={ref}
        onClick={e => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
      </div>
    ),
  );
  /* eslint-enable sonarjs/no-identical-functions */
  const onDropdownToggle = status => {
    setDropdownState(status);
  };

  return (
    <div className='d-flex'>
      <div id='track-trace-group-filter-wrp' style={{ maxWidth: '300px' }}>
        <Dropdown
          id='track-trace-group-filter'
          className='page-size-selector'
          onToggle={onDropdownToggle}
          drop={'right'}
          flip={'false'}
        >
          <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
            <span className={`btn-label text-primary pe-2 `}>
              {selectedVal.key ? selectedVal.label : 'Select'}
            </span>
            <ChevronDownIcon />
          </Dropdown.Toggle>
          <Dropdown.Menu align={{ sm: 'right' }}>
            {filters.map(filter => (
              <Dropdown.Item
                onClick={e => onDropdownSelect(filter)}
                key={filter.key}
                eventKey={filter.key}
                active={filter.key === selectedVal.key}
                className='track-trace-grp-dropdown-item'
              >
                {filter.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
});
PsFilterDropDown.propTypes = {};
const groupFilterList = [
  { key: GROUPS.NONE.key, label: GROUPS.NONE.label },
  {
    key: GROUPS.ORDER.key,
    label: GROUPS.ORDER.label,
  },
  {
    key: GROUPS.PARENT_ORDER.key,
    label: GROUPS.PARENT_ORDER.label,
  },
  {
    key: GROUPS.CUSTOMER.key,
    label: GROUPS.CUSTOMER.label,
  },
  {
    key: GROUPS.ORDER_EXTERNAL_REFERENCE.key,
    label: GROUPS.ORDER_EXTERNAL_REFERENCE.label,
  },
  {
    key: GROUPS.SECONDARY_CUSTOMER.key,
    label: GROUPS.SECONDARY_CUSTOMER.label,
  },
  {
    key: 'EDGE',
    label: 'Edge',
  },
  {
    key: 'POD',
    label: 'Station',
  },
];
const PsGroupFilterDropdown = memo(() => {
  const dispatch = useDispatch();
  const { production_summary_group } = useSelector(state => state.orderstate);
  const [filters] = useState(groupFilterList);
  const [selectedGroup, setSelectedGroup] = useState({
    key: GROUPS.PARENT_ORDER.key,
    label: GROUPS.PARENT_ORDER.label,
  });
  useEffect(() => {
    const filterVal =
      filters.filter(val => val.key === production_summary_group)[0] || {};
    setSelectedGroup(filterVal);
  }, [filters, production_summary_group]);

  const onDropdownSelect = data => {
    dispatch(setProductionSummaryGroup(data.key));
  };

  return (
    <PsFilterDropDown
      filters={filters}
      selectedVal={selectedGroup}
      onDropdownSelect={onDropdownSelect}
    />
  );
});

PsGroupFilterDropdown.propTypes = {};

const PsGroup2FilterDropdown = memo(() => {
  const dispatch = useDispatch();
  const { production_summary_group, production_summary_group2 } = useSelector(
    state => state.orderstate,
  );
  const [filters, setFilters] = useState([...groupFilterList]);
  const [selectedGroup, setSelectedGroup] = useState({
    key: GROUPS.NONE.key,
    label: GROUPS.NONE.label,
  });
  useEffect(() => {
    const filterVal =
      filters.filter(val => val.key === production_summary_group2)[0] || {};
    setSelectedGroup(filterVal);
  }, [filters, production_summary_group2]);
  useEffect(() => {
    setFilters([
      ...groupFilterList.filter(val => {
        return val.key !== production_summary_group;
      }),
    ]);
  }, [setFilters, production_summary_group]);

  const onDropdownSelect = data => {
    dispatch(setProductionSummaryGroup2(data.key));
  };

  return (
    <PsFilterDropDown
      filters={filters}
      selectedVal={selectedGroup}
      onDropdownSelect={onDropdownSelect}
    />
  );
});
const PsUnitTypeFilterDropdown = memo(() => {
  const dispatch = useDispatch();
  const { ps_unit_type } = useSelector(state => state.orderstate);
  const [filters] = useState([
    {
      key: 'FEET',
      label: 'Feet',
    },
    {
      key: 'METER',
      label: 'Meter',
    },
  ]);
  const [selectedUnitType, setSelectedUnitType] = useState({
    key: 'ALL',
    label: 'All',
  });
  useEffect(() => {
    const filterVal = filters.filter(val => val.key === ps_unit_type)[0] || {};
    setSelectedUnitType(filterVal);
  }, [filters, ps_unit_type, setSelectedUnitType]);

  const onDropdownSelect = data => {
    dispatch(setPsUnitType(data.key));
  };

  return (
    <PsFilterDropDown
      filters={filters}
      selectedVal={selectedUnitType}
      onDropdownSelect={onDropdownSelect}
    />
  );
});

export const ProductionSummaryViewModes = ({
  operations,
  filterCb,
  viewMode,
}) => {
  //const [isMobile] = useMobile();
  const dispatch = useDispatch();
  const { ps_filter_custom_date_range } = useSelector(
    state => state.orderstate,
  );
  const [customDateRange, setCustomDateRange] = useState(
    ps_filter_custom_date_range || { preset: 'today' },
  );
  useEffect(() => {
    setCustomDateRange(ps_filter_custom_date_range || { preset: 'today' });
  }, [ps_filter_custom_date_range]);
  const handleDateChange = (fromDate, toDate, preset) => {
    setCustomDateRange({ from: fromDate, to: toDate, preset });
    dispatch(setPsCustomDateRange({ from: fromDate, to: toDate, preset }));
  };
  return (
    <Fragment>
      <div className={'row'}>
        <div className={'col-12 col-md-6 pt-2'}>
          <div className='d-flex justify-content-start align-items-center primary-text-color'>
            <div>
              <div
                className='text-label ps-1'
                onClick={e => dispatch(setPsOperationCode(null))}
              >
                Select Operation
              </div>
              <div className='position-relative ' style={{ maxWidth: '300px' }}>
                {viewMode === 'RFT' ? (
                  <PsRFTOperationDropdown operations={operations} />
                ) : (
                  <PsOperationDropdown operations={operations} />
                )}
              </div>
            </div>
            {!(viewMode === 'RFT') && (
              <div>
                <div
                  className='text-label ps-1'
                  onClick={e => dispatch(setPsItemType('ALL'))}
                >
                  Item Type
                </div>
                <div
                  className='position-relative '
                  style={{ maxWidth: '300px' }}
                >
                  <PsItemTypeFilterDropdown />
                </div>
              </div>
            )}
            <div>
              <div
                className='text-label ps-1'
                onClick={e =>
                  dispatch(setProductionSummaryGroup('PARENT_ORDER'))
                }
              >
                Group 1
              </div>
              <div className='position-relative ' style={{ maxWidth: '300px' }}>
                <PsGroupFilterDropdown />
              </div>
            </div>
            <div>
              <div
                className='text-label ps-1'
                onClick={e => dispatch(setProductionSummaryGroup2('NONE'))}
              >
                Group 2
              </div>
              <div className='position-relative ' style={{ maxWidth: '300px' }}>
                <PsGroup2FilterDropdown />
              </div>
            </div>
            {viewMode === 'RFT' && (
              <div>
                <div
                  className='text-label ps-1'
                  onClick={e => dispatch(setProductionSummaryGroup2('NONE'))}
                >
                  Units
                </div>
                <div
                  className='position-relative '
                  style={{ maxWidth: '300px' }}
                >
                  <PsUnitTypeFilterDropdown />
                </div>
              </div>
            )}
          </div>
          <DateRangePickers
            from_date={customDateRange.from}
            to_date={customDateRange.to}
            preset={customDateRange.preset}
            onDateChangeCb={handleDateChange}
          />
        </div>
      </div>
    </Fragment>
  );
};
